.problems{
    .main_title{
        margin-top: 30px;
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        @media (min-width: 480px) {
            font-size: 30px;
            line-height: 40px;
        }
    }
    .list_title{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
        list-style-type: none;
        text-align: left;
    }
    .list_main{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 30px;
        list-style-type: none;
        text-align: left;
        line-height: 22px;
    }
}