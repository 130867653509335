@import '../../assets/mixin.scss';

.conditions{
    &__title{
        h2{
            @include bannerTitle;
        }
    }
    &__subTitle{
            @include subTitle;
    }
    &__table{
        width: 100%;
        max-width: 100%;
        &__head{
            tr, th {
            background-color: #f4f6f5;
            vertical-align: middle;
            font-weight: bold;
            padding: 10px 20px;
            border-left: 1px solid #c6c6c6;
            border-top: 1px solid #c6c6c6;
            border-bottom: 1px solid #c6c6c6;
            }
        }
        &__body{
            tr, td {
                vertical-align: middle;
                padding: 10px 20px;
                border-left: 1px solid #c6c6c6;
                border-top: 1px solid #c6c6c6;
                border-bottom: 1px solid #c6c6c6;
                }
        }
        tr, td:first-child{
            border-left: none;
            text-align: left;
        }
        tr, td:last-child{
            min-width: 350px;
        }
    }
    &__mobile{
        @media (max-width: 768px) {
            overflow-x: scroll;
        }
    }

}