.risk_banner{
    text-align: center;
    background-color: rgb(226, 226, 226);
    .inactive{
        display: none;
    }
    .active{
        height: 100%;
        display: block !important;
       }
    .text{
        color: rgb(122, 122, 122);
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
       @media (max-width: 1200px) {
            display: none;
       } 
    }
    .risk_btn{
        border: 0;
        background-color: transparent;
        @media (min-width:1200px) {
            display: none;
        }
    }
    .sticky-bottom{
        z-index: 999;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: rgb(226, 226, 226);
    }
}
