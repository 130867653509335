@import '../../assets/variables.scss';

.whyUs {
    text-align: center;
    padding: 70px 0;
    @media (max-width: 768px) {
        padding: 0px 0 20px;
    }

    &__title {
        color: $black;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 50px;
        @media (max-width: 991px) {
            text-align: left;
        }
    }

    &__subtitle {
        color: rgba(18, 30, 42, .6);
        margin: 0 auto 70px;
        width: 50%;

        @media (max-width: 991px) {
            text-align: left;
            width: 100%;
        }
    }

    &__boxes {
        display: grid;
        grid-template: 1fr/ 1fr 1fr 1fr;
    }

    .zoomBox {
        height: 205px;
        overflow: hidden;
        padding: 40px 15px 20px;
        text-align: center;
        transition: all .2s;
        border: solid rgba(18, 30, 42, .05);
        border-width: 0;
        img{
            width: 60px;
        }
        &__title {
            color: $black;
            font-size: 16px;
            font-weight: 600;
            margin: 10px 0 0;
            padding: 0;
        }

        &__text {
            color: rgba(18, 30, 42, .6);
            display: none;
            font-size: 11px;
            line-height: 17px;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        &:first-child {
            border-width: 0 1px 1px 0;
        }

        &:nth-child(2) {
            border-width: 0 0 1px 0;
        }

        &:nth-child(3) {
            border-width: 0 0 1px 1px;
        }

        &:nth-child(5) {
            border-width: 0 1px 0 1px;
        }

        &:hover {
            background-color: #fff;
            border-right: 0;
            box-shadow: 0 25px 50px 0 rgb(29 49 68 / 15%);
            padding: 20px 15px;
            text-align: center;
            transform: scale(1.3);
            z-index: 9999;

            .zoomBox {
                &__title {
                    margin: 10px 0;
                }

                &__text {
                    display: inline-block;
                }
            }
        }
    }
}



.accordion {
    &__collapse {
        opacity: 0;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        overflow: hidden;

        p {
            text-align: left;
            width: 100%;
            background: transparent;
            border-top: none;
            color: rgba(18, 30, 42, .6);
            font-size: 16px;
            line-height: 21px;
            margin: 0;
            padding: 0 50px 10px 25px;
        }
    }


    &__row {
        border-top: 1px solid rgba(18, 30, 42, .05);
        background: transparent;
        border-radius: 0;
        box-shadow: none;
        margin: 0;

        &:first-child {
            border: none;
        }

        &.open {
            .accordion__collapse {
                opacity: 1;
            }

            .accordion__arrow {
                transform: rotate(0deg);
            }
        }
    }


    &__btn {
        align-items: center;
        background: transparent;
        cursor: pointer;
        display: flex;
        min-height: 55px;
        padding: 10px 40px 10px 80px;
        position: relative;
        text-align: left;

        h3 {
            color: $black;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            margin: 0;
            padding: 0;
        }
    }

    &__image {
        display: block;
        height: 35px;
        left: 15px;
        margin: -17.5px 0 0;
        position: absolute;
        top: 50%;
        width: 35px;
    }

    &__arrow {
        display: block;
        height: 15px;
        margin: -8px 0 0;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: rotate(180deg);
        width: 15px;
        transition: all 0.2s ease;
    }
}