@import '../../../../assets/mixin.scss';
@import '../../../../assets/variables.scss';

.depo_Withd {
    &__items {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        margin-bottom: 24px;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    &__item {
        text-decoration: none;
        padding: 0px 8px;
        font-weight: 500;
        white-space: nowrap;
        user-select: none;
        font-size: 18px;
        color: $prime;
        cursor: pointer;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #4572C4;
            transform: scaleX(0);
            transition: transform 0.3s ease;
        }

        &--selected {
            &::before {
                transform: scaleX(1);
            }
        }

        &:hover {
            color: #ff5622d3;
        }
    }

    &__tableBox {
        padding: 20px 0 100px;

        @media (max-width: 768px) {
            overflow: scroll;
        }
    }

    .depo_Table {
        text-align: center;
        box-shadow: 1px 1px 10px rgb(221 221 221 / 60%);
        width: 100%;
        max-width: 100%;

        &__head {
            border: 0 solid;
            border-color: inherit;

            tr,
            th {
                background-color: #f4f6f5;
                vertical-align: middle;
                font-weight: bold;
                padding: 10px;
                border-left: 1px solid #c6c6c6;
                border-top: 1px solid #c6c6c6;
                border-bottom: 1px solid #c6c6c6;
            }

            tr,
            th:first-child {
                border-left: 0px;
            }
        }

        &__body {
            &__td {
                border-left: 1px solid #c6c6c6;
            }

            tr,
            td {
                vertical-align: middle;
                padding: 10px 20px;
                border-top: 1px solid #c6c6c6;
                border-bottom: 1px solid #c6c6c6;
            }
        }

        tr,
        td:nth-child(2) {
            width: 160px;
        }

        tr,
        td:last-child {
            max-width: 180px;
        }

        &__first {
            text-align: start;
        }

    }

}