@import '../../../../assets/variables.scss';

.burger {
    position: absolute;
    right: 0;

    &__btn {
        cursor: pointer;
        position: relative;
        z-index: 100;

        @media (min-width: 320px) {
            padding: 0 15px;
        }
    }

    &__menu {
        background-color: white;
        height: 100vh;
        overflow: scroll;
        padding: 64px 15px 190px;
        position: absolute;
        right: -100vw;
        top: -3px;
        transition: right .2s ease-in;
        width: 100vw;
        z-index: 2;

        @media (min-width: 768px) {
            padding: 64px 30px 90px;
            right: -464px;
            width: 464px;
        }
    }

    &__nav {
        font-size: 16px;
        margin: 0;
        padding: 0;
    }

    &--opened {
        animation: burger-open 0.2s ease;
        right: 0;
        opacity: 1;
    }

    &__link {
        border-bottom: 1px solid rgba(24, 24, 30, .1);
        padding: 18px 15px 18px 0;
        color: $black;
        display: block;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        position: relative;
        text-align: left;
        width: 100%;

        a {
            color: $black;
            text-decoration: none;
        }
    }

    &__links {
        marker: none;
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;

        a {
            color: black;
            text-decoration: none;
        }
    }
}

@keyframes burger-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.nav-icon {
    width: 30px;
    height: 30px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $prime;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 10px;
        }

        &:nth-child(4) {
            top: 20px;
        }
    }

    &.open {
        span {
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }

}