@import '../../assets/variables.scss';
@import '../../assets//mixin.scss';

.contract{
    margin-bottom: 80px;
    &__title{
        padding: 0 0 50px;
        h2{
        font-weight: 600;
        font-size: 48px;
        }
    }
    .contract_item {
        padding: 0px 8px;
        font-weight: 500;
        white-space: nowrap;
        user-select: none;
        font-size: 16px;
        cursor: pointer;
        color: $prime;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $prime;
            transform: scaleX(0);
            transition: transform 0.3s ease;
        }

        &--selected {
            &::before {
                transform: scaleX(1);
            }
        }

        &:hover {
            color: $prime;
        }
    }
    &_panel__row{
        text-align: center;
        display: grid;
        grid-template-columns: 150px 250px 150px 100px 100px 130px 120px 110px;
        align-items: center;
        justify-items: center;
        padding: 5px 0;
    }
    .contract_panel{
        text-align: center;
        display: grid;
        grid-template-columns: 150px 250px 150px 100px 100px 130px 120px 110px;
        align-items: center;
        justify-items: center;
        padding: 5px 0;
    } 
    
    .grey-bg{
        background-color: rgb(242, 240, 240);
        @media (max-width: 770px) {
            width: 154%;
        }
        @media (max-width: 425px) {
            width: 316%;
        }
        @media (max-width: 375px) {
            width: 355%;
        }
    }
    .overflow{
        @media (max-width: 770px) {
            overflow-x: scroll;
        }
    }
}





.contract-banner {
    &__head {
        width: 100%;
        position: relative;
        margin-top: -38px;
        padding: 50px 0 285px 0;
        overflow: hidden;

        @media (min-width: 1024px) {
            padding: 50px 0 320px 0;
        }

        &::before {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: "";
            z-index: -1;
            transition: all 0.4s ease;
            // max-height: 600px;
            overflow: hidden;

            @media (min-width: 1440px) {
                animation: 10s ease 1s infinite alternate background-animation;
            }
        }

        &--changing {
            &::before {
                animation: changing-bg 0.6s ease;
            }
        }

        .container {
            display: flex;
            position: relative;
        }
    }

    &__instruments {
        margin-top: -270px;
        position: relative;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        margin-bottom: 24px;
    }

    &__item {
        padding: 0px 8px;
        font-weight: 500;
        white-space: nowrap;
        user-select: none;
        font-size: 16px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: white;
            transform: scaleX(0);
            transition: transform 0.3s ease;
        }

        &--selected {
            &::before {
                transform: scaleX(1);
            }
        }

        &:hover {
            color: rgb(255, 255, 255, 0.8);
        }
    }

    &__title {
        @include bannerTitle;
        line-height: 50px;
        text-align: left;
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -1px;
        color: white;
    }
    &__subTitle{
        color: #c1c1c1;
    }

    &__btns {
        margin-top: 20px;
        display: flex;
        gap: 16px;
    }

    &__btn {
        @include btn;
        a {
            color: white;
            text-decoration: none;
        }

        &--real {
            color: white;
            background-color: $prime;
            border-color: $prime;

            &:hover {
                color: white !important;
                background-color: $prime-light;
                border-color: $prime-light;
            }
        }

        &--demo {
            color: white;
            border-color: white;

            &:hover {
                color: $black;
                background-color: white;
            }
        }
    }


    &-table {
        background-color: white;
        box-shadow: rgb(0 0 0 / 5%) 0px 0px 16px 1px;
        border-radius: 4px;
        overflow: hidden;
        font-size: 16px;
        color: $black;
        line-height: 1.45;
        font-weight: 500;
        min-height: 430px;
        position: relative;
        z-index: 6;
        margin-bottom: 100px;

        p {
            margin: 0;
            padding: 0;
        }

        &__head {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
            padding: 18px 24px;
            background-color: rgb(250, 250, 254);
        }

        &__title {
            color: $black;
            text-decoration: none;
            font-size: 24px;
            line-height: 32px;

            .arrow_banner {
                width: 8px;
                height: 14px;
                margin-left: 5px;
                color: $prime;
                transition: color 0.25s ease 0s, transform 0.3s ease 0s;
            }

            &:hover {
                color: $black;

                .arrow_banner {
                    transform: translateX(3px) scale(0.9);
                    color: $prime-light;
                }
            }
        }

        &__leverages {
            margin-left: auto;
        }

        &__leverages,
        &__investment {
            p {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;

                &:first-child {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    margin-bottom: 1px;
                }
            }

            width: 25%;
        }
        &__rows {
            padding: 17px 24px 14px;
            display: grid;
            grid-template-columns: 3fr 1fr 1fr 2fr 2fr;
            margin: 0 24px;
            border-bottom: 1px solid rgb(244, 244, 249);

            &--first {
                color: rgb(155, 155, 155);
                font-size: 16px;
                line-height: 20px;
                @media (min-width: 769px) {
                    border-bottom: 1px solid rgb(225, 225, 225);
                }
            }
        }

        &__column {
            // padding-right: 24px;
            display: flex;
            align-items: center;

            &--positive {
                color: #35a947;

                .banner-table__btn {
                    color: #35a947;
                    border-color: #35a947;

                    &:hover {
                        background-color: #35a947;
                        color: white;
                    }
                }
            }

            &--negative {
                color: #e34828;

                .banner-table__btn {
                    color: #e34828;
                    border-color: #e34828;

                    &:hover {
                        background-color: #e34828;
                        color: white;
                    }
                }
            }

            p {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;

                &:nth-child(2) {
                    line-height: 14px;
                    color: rgb(155, 155, 155);
                    margin-top: 2px;
                    font-weight: 300;
                }
            }

            img {
                width: 32px;
                height: 32px;
                margin-right: 12px;
            }
        }

        &__chart {
            height: 42px;
            width: 110px;
            margin-left: 10px;

            @media (min-width: 1024px) {
                margin-left: auto;
                margin-right: 10px;
            }
        }

        &__btn {
            color: #35a947;
            font-size: 16px;
            line-height: 1;
            padding: 18px 0px;
            border: 1px solid #35a947;
            border-radius: 4px;
            text-align: center;
            text-transform: uppercase;
            transition: all 0.3s ease;
            white-space: nowrap;
            text-decoration: none;
            width: 100%;
        }
    }
}

@keyframes changing-image {
    0% {
        transform: perspective(50px) translateZ(-200px);
        opacity: 0;
    }

    100% {
        transform: perspective(50px) translateZ(0px);
        opacity: 1;
    }
}

@keyframes changing-bg {
    0% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

@keyframes background-animation {
    0% {
        background-position: bottom;
        transform: perspective(240px) translateZ(0px);
    }

    25% {
        background-position: right;
    }

    75% {
        background-position: left;
    }

    100% {
        background-position: top;
        transform: perspective(240px) translateZ(50px);
    }
}