@import '../../../../assets/variables.scss';

.news-tape {
    margin: 70px 0 0;
    overflow: hidden;

    h2 {
        font-size: 36px;
        font-weight: 600;
        margin: 0 0 70px;
    }

    &__swiper {
        overflow: visible;
        position: relative;
        list-style: none;
        margin-left: auto;
        margin-right: auto;
        // padding: 0;
        z-index: 1;

        .swiper-wrapper {
            box-sizing: content-box;
            display: flex;
            height: 100%;
            margin-bottom: 50px;
            position: relative;
            transition-property: transform;
            width: 100%;
            z-index: 1;
            min-height: 400px;
        }
    }

    &__slide {
        background: #fff url(./assets/plug.jpg) 100% no-repeat;
        background-size: cover;
        background-position: center;
        box-shadow: 0 10px 50px 0 rgb(0 0 0 / 8%);
        color: #000;
        float: left;
        height: 330px;
        position: relative;
        top: 0;
        transition: top .2s linear, box-shadow .2s ease-in-out;
        width: 270px;

        @media (max-width: 575px) {
            width: 270px;
        }

        &:after {
            background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), rgba(18, 30, 42, .5));
            content: "";
            height: 325px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &:hover {
            @media (min-width: 992px) {
                box-shadow: 0 10px 50px 0 rgb(0 0 0 / 10%);
                top: -12px;
            }
        }

    }

    &__body {
        bottom: 75px;
        left: 0;
        padding: 25px;
        position: absolute;
        width: 100%;
        z-index: 1;

        p {
            background: rgba(18, 30, 42, .3);
            color: #fff;
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            margin: 0 0 10px;
            padding: 1px 5px;
            text-transform: uppercase;
        }

        h3 {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            margin: 0;
            overflow: hidden;
            -webkit-line-clamp: 4;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    &__footer {
        background-color: #fff;
        bottom: 0;
        color: $prime;
        font-size: 16px;
        font-weight: 600;
        height: 75px;
        left: 0;
        line-height: 25px;
        padding: 25px;
        position: absolute;
        text-transform: uppercase;
        width: 100%;
        z-index: 1;
    }

    &__navigation {
        button {
            background: none;
            border: none;
            padding: unset;
            margin: 0 10px;


            &:first-child {
                img {
                    transform: rotate(180deg);
                    margin-bottom: 2px;
                }
            }
        }
    }
}