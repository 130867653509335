@import '../../../../assets/variables.scss';
@import '../../../../assets/mixin.scss';

.tradingStart{
    text-align: center;
    padding-top: 15px;
    padding-bottom: 100px;
    @media (max-width: 768px) {
        padding-bottom: 100px;
    }
    &__card{
        width: 300px;
        margin: 10px;
        position: relative;
        box-shadow: 0 0 15px #e4e4e4;
        padding: 30px 20px 30px;
    }
    .title{
        font-size: 36px;
        font-weight: 600;
    }
    .cardbox{
        justify-content: center;
        &__card{
            padding: 20px;
            width: 350px;
            img{
                height: 70px;
            }
            h2{
                min-height: 70px;
                font-size: 18px;
                // margin: 20px 0 40px;
                font-weight: 600;
            }
            span{
                color: rgba(18, 30, 42, 0.6);
            }
        }
    }
    .auxiliary{
        display: flex;
        justify-content: center;
    }
    .btn{
        text-align: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        @media (min-width: 768px) {
            margin: 12px 10px 0 0;
        }
        @include btn;
        &--join{
          background-color: $prime;
          border-color: $prime;
          border-radius: 3px;
          color: #fff;
          &:hover{
            background-color: $prime-light;
            color: #fff;
            }
        }
    }
}