
@import '../../../../assets/variables.scss';

.payment{
    color: $black;
    padding-top: 15px;
    text-align: center;
    @media (max-width: 770px) {
        text-align: left;
    }
    .title{
        font-size: 16px;
        h2{
            font-size: 36px;
            font-weight: 600;
        }
    }
    .list{
        margin: 50px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        // img{
        //     max-width: 8%;
        // }
    }
    .side-text{
        font-size: 16px;
    }
}