@import "../../../../assets/variables.scss";
.FAQ{
    
    &__title{
        color: $black;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .accordion {
        &__title{
            font-size: 30px;
            font-weight: 600;
        }
        &__collapse {
            opacity: 0;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            overflow: hidden;
    
            p {
                text-align: left;
                width: 100%;
                background: transparent;
                border-top: none;
                color: rgba(18, 30, 42, .6);
                font-size: 16px;
                line-height: 21px;
                margin: 0;
                padding: 0 50px 10px 15px;
            }
        }
    
    
        &__row {
            border-top: 1px solid rgba(18, 30, 42, .05);
            background: transparent;
            border-radius: 0;
            box-shadow: none;
            margin: 0;
    
            &:first-child {
                border: none;
            }
    
            &.open {
                .accordion__collapse {
                    opacity: 1;
                }
    
                .accordion__arrow {
                    transform: rotate(0deg);
                }
            }
        }
    
    
        &__btn {
            align-items: center;
            background: transparent;
            cursor: pointer;
            display: flex;
            min-height: 55px;
            padding: 10px 40px 10px 20px !important;
            position: relative;
            text-align: left;
    
            h3 {
                color: $black;
                font-size: 26px;
                font-weight: 600;
                line-height: 34px;
                margin: 0;
                padding: 0;
                @media (max-width: 760px) {
                    line-height: 30px;
                }
            }
        }
    
        &__arrow {
            display: block;
            height: 15px;
            margin: -8px 0 0;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: rotate(180deg);
            width: 15px;
            transition: all 0.2s ease;
        }
    }
}