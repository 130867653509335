.downloadOur {
    h4 {
        position: relative;
        display: block;
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        opacity: 0.5;
    }

    .icons_group {
        vertical-align: bottom;
        margin-bottom: 30px;

        @media (min-width: 768px) {
            display: flex;
            flex-direction: row;
            -webkit-box-pack: justify;
            justify-content: space-evenly;
            align-items: flex-end;
        }

        &__comp {
            position: relative;
            display: inline-block;
            width: 152px;
            height: 114px;
            margin-left: 55px;
            z-index: 1;

            @media (min-width:840px) {
                width: 197.6px;
                height: 148.2px;
                margin-left: 0px;
            }
        }

        &__notebook {
            position: relative;
            display: inline-block;
            width: 137px;
            height: 78px;
            margin-left: 55px;
            z-index: 2;
            top: 1px;

            @media (min-width:840px) {
                width: 178.1px;
                height: 101.4px;
                margin-left: 0px;
            }
        }

        &__laptop {
            position: relative;
            display: inline-block;
            width: 101px;
            height: 57px;
            margin-left: 0px;
            z-index: 3;

            @media (min-width:840px) {
                width: 131.3px;
                height: 74.1px;
                margin-left: 0px;
            }
        }
    }

    .btn_group {
        @media (min-width: 600px) {
            display: flex;
            flex-flow: row wrap;
            -webkit-box-pack: justify;
            justify-content: space-evenly;
        }

        .downloadBtn {
            text-decoration: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            box-sizing: border-box;
            padding: 12px 20px;
            color: #4a4a4a;
            cursor: pointer;
            transition: background-color .2s ease, color .2s ease, fill .2s ease;
            border-radius: 2px;

            &--orange {
                border: 1px solid #4572C4;
                fill: #4572C4;
            }

            @media (min-width: 600px) {
                max-width: 240px;
                width: 100%;
                margin-bottom: 20px;
            }

            @media (min-width: 840px) {
                max-width: 280px;
                width: calc(50% - 10px);
                min-width: 0px;
            }

            &:hover {
                background-color: #4572C4;
                color: #fff;
                fill: #fff;
            }

            &__text {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                width: calc(100% - 55px);
            }

            &__title {
                width: 100%;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            &__subTitle {
                width: 100%;
                font-size: 16px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .icon_apple {
                width: 35px;
                height: 35px;
                margin-right: 20px;
            }

            .icon_windows {
                width: 32px;
                height: 32px;
                margin-right: 20px;
            }
        }
    }

    .system {
        display: flex;
        padding: 10px 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(255, 247, 240);

        .laptop_svg {
            width: 21px;
            height: 17px;
            margin-right: 15px;
            display: inline-block;
            vertical-align: -3px;
        }

        h2 {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;

            @media (min-width: 480px) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .system_list {
        display: flex;
        flex-flow: row wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;

        &__info {
            display: inline-block;
            color: rgb(29, 35, 48);
            list-style-type: none;

            @media (min-width: 600px) {
                width: 50%;
                padding-left: 20px;
                box-sizing: border-box;
            }

            &__title {
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                margin-bottom: 10px;
                list-style-type: none;
            }

            &__main {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                margin-bottom: 20px;
                list-style-type: none;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .side_panel {
        padding: 0 60px;

        .phone_group {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            vertical-align: bottom;
            text-align: center;
            margin-bottom: 30px;

            @media (min-width: 840px) {
                margin-left: auto;
                margin-right: auto;
            }

            @media (min-width: 960px) {
                margin-left: auto;
                margin-right: auto;
            }

            &__phone {
                position: relative;
                display: inline-block;
                width: 114.8px;
                height: 57.4px;
                margin-left: -49px;
                z-index: 3;
                top: 1px;

                @media (min-width: 600px) {
                    width: 98.4px;
                    height: 49.2px;
                    margin-left: -42px;
                }

                @media (min-width: 960px) {
                    width: 88.56px;
                    height: 44.28px;
                    margin-left: -37.8px;
                }
            }

            &__pad {
                position: relative;
                display: inline-block;
                width: 138.6px;
                height: 95.2px;
                margin-left: -70px;
                z-index: 2;
                top: 1px;

                @media (min-width: 600px) {
                    width: 118.8px;
                    height: 81.6px;
                    margin-left: -60px;
                }

                @media (min-width: 960px) {
                    width: 106.92px;
                    height: 73.44px;
                    margin-left: -54px;
                }
            }

            &__tab {
                position: relative;
                display: inline-block;
                width: 126px;
                height: 184.8px;
                margin-left: 0px;
                z-index: 1;

                @media (min-width: 600px) {
                    width: 108px;
                    height: 158.4px;
                    margin-left: 0px;
                }

                @media (min-width: 960px) {
                    width: 97.2px;
                    height: 142.56px;
                    margin-left: 0px;
                }
            }

        }

        .download_group {
            padding: 5px 0;
            width: 100%;
            float: none;
            display: flex;
            flex-direction: column;

            .android {
                margin-bottom: 20px;
            }

            .download_btn {
                text-decoration: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                box-sizing: border-box;
                padding: 12px 20px;
                color: #4a4a4a;
                cursor: pointer;
                transition: background-color .2s ease, color .2s ease, fill .2s ease;
                border-radius: 2px;

                &__black {
                    border: 1px solid #4a4a4a;
                    fill: #000000;

                    &:hover {
                        background-color: #4a4a4a;
                        color: #fff;
                        fill: #fff;
                    }
                }

                &__icon {
                    width: 35px;
                    height: 35px;
                    margin-right: 30px;
                }

                &__icon_text {
                    width: 117px;
                    height: 44px;
                }

                &__text {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 55px);
                }

                &__title {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                &__subTitle {
                    width: 100%;
                    font-size: 16px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .apk {
                    max-width: 300px;
                    background-color: rgb(255, 255, 255);
                    margin-bottom: 0px !important;

                    @media (min-width: 600px) {
                        min-width: 230px;
                        display: flex;
                        margin-bottom: 20px;
                    }

                    @media (min-width: 840px) {
                        min-width: 270px;
                    }

                    @media (min-width: 960px) {
                        min-width: 0px;
                        width: 100%;
                    }

                    @media (min-width: 1280px) {
                        max-width: 300px;
                    }
                }
            }
        }

        .mob_sys_req {
            display: flex;
            padding: 10px 20px;
            margin-top: 30px;
            margin-bottom: 20px;
            border-radius: 3px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: rgb(250, 250, 254);

            @media (min-width: 840px) {
                width: 100%;
                box-sizing: border-box;
                display: flex;
            }

            &__title {
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                margin-bottom: 0 !important;

                @media (min-width: 480px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .phone_svg {
                width: 12px;
                height: 19px;
                margin-right: 15px;
                display: inline-block;
                vertical-align: -4px;
            }
        }
    }
}