.what_you{
    .swiper {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        /* Fix of Webkit flickering */
        z-index: 1;
      }
      .swiper-vertical > .swiper-wrapper {
        flex-direction: column;
      }
      .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
      }
      .swiper-android .swiper-slide,
      .swiper-wrapper {
        transform: translate3d(0px, 0, 0);
      }
      .swiper-pointer-events {
        touch-action: pan-y;
        &.swiper-vertical {
          touch-action: pan-x;
        }
      }
      .swiper-slide {
        padding-left: 10px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        height: 500px;
        position: relative;
        transition-property: transform;
      }
      .swiper-slide-invisible-blank {
        visibility: hidden;
      }
      /* Auto Height */
      .swiper-autoheight {
        &,
        .swiper-slide {
          height: auto;
        }
      
        .swiper-wrapper {
          align-items: flex-start;
          transition-property: transform, height;
        }
      }
      .swiper-backface-hidden .swiper-slide {
        transform: translateZ(0);
        backface-visibility: hidden;
      }
      /* 3D Effects */
      .swiper-3d {
        &,
        &.swiper-css-mode .swiper-wrapper {
          perspective: 1200px;
        }
        .swiper-wrapper,
        .swiper-slide,
        .swiper-slide-shadow,
        .swiper-slide-shadow-left,
        .swiper-slide-shadow-right,
        .swiper-slide-shadow-top,
        .swiper-slide-shadow-bottom,
        .swiper-cube-shadow {
          transform-style: preserve-3d;
        }
        .swiper-slide-shadow,
        .swiper-slide-shadow-left,
        .swiper-slide-shadow-right,
        .swiper-slide-shadow-top,
        .swiper-slide-shadow-bottom {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          z-index: 10;
        }
        .swiper-slide-shadow {
          background: rgba(0, 0, 0, 0.15);
        }
        .swiper-slide-shadow-left {
          background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
        }
        .swiper-slide-shadow-right {
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
        }
        .swiper-slide-shadow-top {
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
        }
        .swiper-slide-shadow-bottom {
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
        }
      }
    .name{
        font-size: 16px;
        font-weight: 600;
    }
    .job{
        color: #4a4a4a;
        font-size: 16px;
    }
    .text{
        color: #4a4a4a;
        font-size: 16px;
        line-height: 22px;
    }
    .card{
        box-shadow: 0 0 16px 1px rgb(0 0 0 / 5%);
        border-radius: 2px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 40px;
        margin-right: 24px;
        min-height: 250px;
        width: 350px;
    }
    .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
        font-size: 16px;
        background: #fff;
      
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
      }
      
      .swiper-slide img {
        display: block;
        width: 60px;
        height: 60px;
        object-fit: cover;
      }
      
    .btn{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #fff;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
        opacity: .7;
        transition: opacity .3s;
        box-shadow: 0 0 16px 1px rgb(0 0 0 / 5%);
    }
    
}