.accountPage{
  text-align: center;

  h1 {
    font-size: 48px !important;
    font-weight: 600 !important;
  }
  
  .tableBlock{
    overflow: scroll;

    table{
      width: 100%;
    }
  }

  .typesCardBox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;

    .typeCard{
      border: solid 1px #eee;
      border-radius: 50px;
      padding: 20px;

      &__titleBox{
        display: flex;
        height: 50px;
        align-items: flex-end;
        padding: 10px 10px 0px;

        h2{
          margin: 0;
        }
      }

      hr{
        margin: 5px;
      }

      ul{
        list-style: none;
        padding: 10px;
        text-align: start;

        li{
          display: flex;
          align-items: center;
          gap: 5px;

          .checkmark {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            position: relative;

            &--active{
              background-color: green;
            }

            &--inactive{
              background-color: rgb(195, 195, 195);
            }
          }
          
          .checkmark::before {
            content: '';
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            position: absolute;
          }
        }
      }
    }
  }

  table{
    thead{
      tr{
        border-bottom: 1px solid rgb(225, 225, 225);
        color: #fff;
        background-color: #4572C4;
        font-size: 16px;
        line-height: 20px;

        th{
          text-align: center;
        }
      }
    }
    tbody{
      tr{
        border-bottom: 1px solid rgb(244, 244, 249);
        td{
          text-align: center;
          margin: 0 24px;
          padding: 7px 24px 7px;
        }
      }
      tr:nth-child(even){
        background: #f4f4f9;
      }
    }
  }
}