.neverTrade{
    padding-top: 50px;
    @media (max-width: 770px) {
    padding-top: 0px;
    }
    .title{
        h2{
            font-size: 36px;
            font-weight: 600;
            margin: 0 0 25px;
            padding: 0;
        }
    }
    .text{
        margin-top: 100px;
        font-size: 16px;
        @media (max-width: 770px) {
            margin-top: 0px;
        }   

    }
    &__title{
            font-size: 30px;
            font-weight: 600;
    }
}